<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0">
        <v-icon class="cursor--pointer" @click="backreport">mdi-reply</v-icon>

        {{ "User Sales Details" }}
      </div>
    </div>
    <v-card :loading="loadingData">
      <v-card-title primary-title>
        <div>
          <h3 class="headline mb-0">{{ `${title}: ${getEmployeeName}` }}</h3>
        </div>
        <v-spacer></v-spacer>
        <pdf-reports
          :dataTable="data"
          :employee="getEmployeeName"
          :title="title"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :items-per-page="10"
          class="elevation-1"
        >
          <template v-slot:[`item.paymentDate`]="{ item }">
            {{ prettyDate(item.createAt) }}
          </template>
          <template v-slot:[`item.patient`]="{ item }">
            {{ item.patient ? item.patient : "-" }}
          </template>
          <template v-slot:[`item.product`]="{ item }">
            {{ getProductName(item) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div>
              {{ item.amount | currency }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import { mapGetters } from "vuex";
import PdfReports from "@/components/PDF/PdfReports.vue";
export default {
  components: { PdfReports },
  name: "details-user-other-sales",
  data() {
    return {
      title: "Other-Payments",
      data: [],
      totalserverData: 0,
      loadingData: false,
    };
  },
  computed: {
    ...mapGetters(["getpatientN", "getSellerN"]),

    headers() {
      return [
        { text: "Type", value: "type", sortable: false },
        { text: "Sales", value: "product", sortable: false },
        { text: "Description", value: "details", sortable: false },
        { text: this.getpatientN + " name", value: "patient", sortable: false },
        { text: "Amount $", value: "amount", sortable: false, align: "end" },
        // { text: "Status", value: "status", sortable: false, align: "center" },
        { text: "Payment date", value: "paymentDate", sortable: false },
        //{ text: "", value: "data-table-expand", sortable: false },
      ];
    },

    getEmployeeName() {
      const item = this.data[0];
      if (item == null || item == undefined) {
        return "-";
      }
      return item.employeeName;
    },
  },
  methods: {
    backreport() {
      this.$router.push("/reports");
    },
    getData() {
      let codf = localStorage.getItem("otp");
      codf = JSON.parse(codf);
      codf = codf.map((cd) => {
        return JSON.parse(atob(cd));
      });
      this.data = codf;
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("MM/DD/YYYY, h:mm a");
    },

    getPatientName(item) {
      if (item.procedure == null) {
        return "-";
      }
      if (item.procedure.patient == null) {
        return "-";
      }
      return item.procedure.patient.fullname;
    },

    getProductName(item) {
      if (item.accesories == null) {
        if (item.procedureName == null) {
          return "-";
        }
        return item.procedureName;
      }
      return item.accesories[0].description;
    },

    getType(item) {
      if (item.payment == null || item.payment == undefined) {
        return "-";
      }
      return item.payment.type;
    },
    typesShow(type) {
      if (type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      if (type == "Stripe") {
        return "Card";
      }
      return type;
    },
    getAmount(item) {
      if (item.payment == null || item.payment == undefined) {
        return 0;
      }
      return item.payment.amount;
    },
  },
  async mounted() {
    await this.getData();
  },
  destroyed() {
    localStorage.removeItem("otp");
  },
};
</script>
<style lang=""></style>
