var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-row align-center justify-space-between mb-4"},[_c('div',{staticClass:"top-header mb-0"},[_c('v-icon',{staticClass:"cursor--pointer",on:{"click":_vm.backreport}},[_vm._v("mdi-reply")]),_vm._v(" "+_vm._s("User Sales Details")+" ")],1)]),_c('v-card',{attrs:{"loading":_vm.loadingData}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v(_vm._s((_vm.title + ": " + _vm.getEmployeeName)))])]),_c('v-spacer'),_c('pdf-reports',{attrs:{"dataTable":_vm.data,"employee":_vm.getEmployeeName,"title":_vm.title}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":10},scopedSlots:_vm._u([{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.prettyDate(item.createAt))+" ")]}},{key:"item.patient",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.patient ? item.patient : "-")+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getProductName(item))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }